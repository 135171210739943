import React from "react"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { Image } from "../components/common/image.component"
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby"
import { linkFragment, linkResolver } from "../link-resolver"
import { Slices } from "../components/slices.component"
import SEO from "../components/common/seo.component"


const BlogPost = ({uri, data}) => {
  const post = data.prismic.allBlog_posts.edges
    .find(item => {
      return uri === linkResolver(item.node._meta);
    })
  if(post) {
    const { content, title, image } = post.node;
    return (
      <Layout>
          <SEO
            title={post.node.page_title || post.node.title}
            description={post.node.description || post.node.text}
            keywords={post.node.keywords}
            image={post.node.preview_image}
            lang={post.node._meta.lang}
          />
          <div className="container">
              <Header theme="light"/>
          </div>
        <div className="container pt-3 blog-post-title text-center">
          <RichText render={title} />
        </div>
        <article className="pt-5">
          <Image className="blog-post-image text-center" image={image}/>
          <div className="blog-post-content container">
                  <RichText render={content} linkResolver={linkResolver}/>
          </div>
        </article>
        <Slices body={post.node.body} />
      </Layout>
    )
  }
  return null;
};

BlogPost.fragments = [linkFragment];

export default BlogPost;

export const query = graphql`
    query postQuery {
        prismic {
            allBlog_posts {
                edges {
                    node {
                        page_title
                        description
                        keywords {
                            keyword
                        }
                        preview_image
                        _meta {
                            type
                            uid
                            lang
                            lastPublicationDate
                        }
                        title
                        content
                        image
                        body {
                            ... on PRISMIC_Blog_postBodyGallery {
                                type
                                label
                                primary {
                                    anchor
                                    bgColor
                                    bgImage
                                    text
                                    title
                                }
                                fields {
                                    image
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_Blog_postBodyPricing_plans {
                                type
                                label
                                fields {
                                    price_units
                                    plan_price
                                    plan_name
                                    plan_features
                                    link_text
                                    link_style
                                    link {
                                        ...link
                                    }
                                    is_free_plan
                                }
                                primary {
                                    bg_image
                                    bg_color
                                    title
                                    text
                                }
                            }
                            ... on PRISMIC_Blog_postBodyText {
                                type
                                label
                                primary {
                                    text
                                    bg_color
                                    bg_image
                                }
                            }
                            ... on PRISMIC_Blog_postBodyFeature {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                }
                                fields {
                                    image
                                    link_style
                                    link_text
                                    text
                                    title
                                    link {
                                        ...link
                                    }
                                }
                            }
                            ... on PRISMIC_Blog_postBodyBlock_with_text_and_image {
                                label
                                type
                                primary {
                                    bg_color
                                    bg_image
                                    min_height
                                    title
                                    text
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_Blog_postBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    form_script
                                    form_url
                                    text
                                }
                            }
                            ... on PRISMIC_Blog_postBodyText {
                                type
                                label
                                primary {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
